const posts = [
  {
    date: "28 Jan, 2025",
    title: "How to Create YouTube Shorts: Long Shorts vs. Short Shorts Explained",
    subtitle: "Learn how to create engaging YouTube Shorts and understand the differences between long Shorts and short Shorts. Discover actionable tips, best practices, and strategies to grow your channel with YouTube Shorts.",
    id: "how-to-create-youtube-shorts-long-vs-short",
    image: require("../assets/images/post/post-19.webp"),
    category: "web-traffic",
    keywords: "How to create YouTube Shorts,Long Shorts vs. short Shorts,YouTube Shorts tips,Best practices for YouTube Shorts,Grow your channel with Shorts",
  },
  {
    date: "21 Dec, 2024",
    title: "How to Rank #1 on Google: A Step-by-Step SEO Blueprint for 2025",
    subtitle: "Learn actionable strategies to rank #1 on Google in 2025. This comprehensive SEO blueprint covers keyword research, content optimization, Core Web Vitals, and more!",
    id: "how-to-rank-1-on-google-seo-blueprint-2025",
    image: require("../assets/images/post/post-17.webp"),
    category: "web-traffic",
    keywords: "SEO blueprint 2025, rank #1 on Google, how to improve website ranking, SEO strategies for 2025, Google SEO tips",
  },
  {
    date: "22 Dec, 2024",
    title: "SEO Strategies for AI-Driven Search Algorithms in 2025",
    subtitle: "Discover the best SEO strategies to rank higher in AI-driven search algorithms in 2025. This guide covers structured data, search intent, and AI tools for success.",
    id: "seo-strategies-ai-search-algorithms-2025",
    image: require("../assets/images/post/post-18.webp"),
    category: "web-traffic",
    keywords: "AI-driven SEO 2025, SEO strategies for AI algorithms, Google MUM optimization, AI SEO tools, structured data SEO",
  },
  {
    date: "18 Dec, 2024",
    title: "How to Make Money Online: A Comprehensive Guide for Beginners",
    subtitle: "Learn how to make money online with proven strategies for beginners. Explore opportunities such as blogging, freelancing, affiliate marketing, dropshipping, online courses, and more. Start your online income journey today.",
    id: "how-to-make-money-online",
    image: require("../assets/images/post/post-16.webp"),
    category: "make-money",
    keywords: "how to make money online, earn money from home, affiliate marketing, blogging for money, online courses, freelancing, dropshipping, passive income, online income strategies, making money online",
  },
  {
    date: "15 Dec, 2024",
    title: "15 Business Lessons From Jeff Bezos to Build a Thriving Business",
    subtitle: "Learn 15 actionable business lessons from Jeff Bezos that will inspire you to innovate, take risks, and achieve long-term success.",
    id: "jeff-bezos-business-lessons",
    image: require("../assets/images/post/post-15.webp"),
    category: "entrepreneurship",
    keywords: "Jeff Bezos business lessons, Amazon success principles, entrepreneurial tips, customer obsession, innovation in business, build a successful business, long-term thinking, risk-taking in business, business growth strategies, Jeff Bezos quotes, entrepreneurship advice",
  },
  {
    date: "13 Dec, 2024",
    title: "Easiest Strategy to Double SEO Traffic Quick | Proven Tips for 2025",
    subtitle: "Discover actionable strategies to double your website's SEO traffic quickly! From mastering keywords to enhancing Core Web Vitals and content clusters, these tips will boost your rankings and drive organic growth in 2025.",
    id: "easiest-strategy-double-seo-traffic",
    image: require("../assets/images/post/post-13.webp"),
    category: "web-traffic",
    keywords: "SEO traffic, double website traffic, increase organic traffic, content clusters, keyword optimization, video SEO, Core Web Vitals, voice search optimization, local SEO tips, backlink strategies",
  },
  {
    date: "12 Dec, 2024",
    title: "How to Get More Youtube Views and Subscribers: Every Proven Strategy",
    subtitle: "Learn the most effective strategies to grow your YouTube channel, boost views, and gain subscribers. Discover tips for SEO, engagement, and high-quality content creation.",
    id: "get-more-youtube-views-subscribers",
    image: require("../assets/images/post/post-11.webp"),
    category: "make-money",
    keywords: "YouTube growth, increase views, gain subscribers, YouTube SEO, video promotion, content strategy, YouTube tips",
  },
  {
    date: "13 Dec, 2024",
    title: "Top 10 Trending Entrepreneurship Topics in 2025 | Boost Your Business Strategies",
    subtitle: "Discover the top 10 entrepreneurship topics shaping 2025. From AI tools to sustainability and personal branding, explore actionable insights to grow your business effectively.",
    id: "top-entrepreneurship-topics-2025",
    image: require("../assets/images/post/post-14.webp"),
    category: "entrepreneurship",
    keywords: "Entrepreneurship, AI for entrepreneurs, Top entrepreneurship topics, Entrepreneurship trends 2024, Startup growth",
  },
  {
    date: "12 Dec, 2024",
    title: "10 Must-Watch Movies for Entrepreneurs to Inspire Growth and Success",
    subtitle: "Discover 10 inspiring movies every entrepreneur should watch to learn about leadership, innovation, and resilience. Perfect for personal and professional growth!",
    id: "must-watch-movies-for-entrepreneurs",
    image: require("../assets/images/post/post-12.webp"),
    category: "business-inspiration",
    keywords: "movies for entrepreneurs, inspiring movies, entrepreneurial lessons, must-watch business movies, leadership movies, motivational movies for entrepreneurs",
  },
  {
    date: "11 Dec, 2024",
    title: "How to Monetize a Blog: 17 Best Strategies for 2025",
    subtitle: "Discover 17 effective strategies to monetize your blog in 2025, from affiliate marketing to selling digital products. Learn how to turn your passion into profit.",
    id: "how-to-monetize-a-blog-best-strategies-2025",
    image: require("../assets/images/post/post-10.webp"),
    category: "make-money",
    keywords: "how to monetize a blog, blog monetization strategies 2025, make money blogging, affiliate marketing, Google AdSense, digital products, sponsored content, e-commerce blogging",
  },
  {
    date: "09 Dec, 2024",
    title: "How to Create Short Videos for YouTube, Instagram, and TikTok | Tips & Tools",
    subtitle: "Learn how to create compelling short videos for YouTube, Instagram, and TikTok with our step-by-step guide. Discover tools, editing tips, SEO strategies, and monetization options",
    id: "how-to-create-short-videos-for-youtube-instagram-and-tiktok",
    image: require("../assets/images/post/post-5.webp"),
    category: "make-money",
    keywords: "how to create short videos, YouTube Shorts, Instagram Reels, TikTok videos, video editing tips, SEO for short videos, monetizing short videos, social media video tips",
  },
  {
    date: "08 Dec, 2024",
    title: "10 Proven Ways to Make Money Online in 2025",
    subtitle: "Explore 10 effective methods to make money online in 2025, including freelancing, blogging, affiliate marketing, and more. Start earning today!",
    id: "10-proven-ways-to-make-money-online-2025",
    image: require("../assets/images/post/post-2.webp"),
    category: "make-money",
    keywords: "make money online, freelancing, blogging, affiliate marketing, online income, passive income, dropshipping, virtual assistant",
  },
  {
    date: "08 Dec, 2024",
    title: "10 Proven Strategies to Get More Traffic to Your Website in 2025",
    subtitle: "Explore effective strategies to boost website traffic in 2025, including SEO, content marketing, social media, and more. Drive sustainable growth with actionable tips",
    id: "get-more-website-traffic-2025",
    image: require("../assets/images/post/post-3.webp"),
    category: "web-traffic",
    keywords: "Website traffic, SEO strategies 2025, content marketing tips, increase web traffic, digital marketing trends 2025",
  },
  {
    date: "09 Dec, 2024",
    title: "Unlocking Business Success: 10 Timeless Strategies for Entrepreneurs",
    subtitle: "Discover 10 actionable business strategies to help entrepreneurs achieve sustainable growth. From market research to leveraging technology, explore proven tips for success",
    id: "unlocking-business-success-strategies-for-entrepreneurs",
    image: require("../assets/images/post/post-9.webp"),
    category: "entrepreneurship",
    keywords: "business advice, entrepreneur strategies, business success tips, sustainable growth, small business advice",
  },
  {
    date: "09 Dec, 2024",
    title: "7 Timeless Business Lessons from Visionary Entrepreneurs",
    subtitle: "Discover seven essential business lessons from successful entrepreneurs like Elon Musk, Oprah Winfrey, and Jeff Bezos. Learn how to start small, adapt to change, and focus on customer excellence for lasting success",
    id: "7-timeless-business-lessons",
    image: require("../assets/images/post/post-1.webp"),
    category: "business-inspiration",
    keywords: "business inspiration, entrepreneurial success, business lessons, visionary entrepreneurs, Jeff Bezos, Elon Musk, Oprah Winfrey",
  },
  {
    date: "09 Dec, 2024",
    title: "How to Make Money Online in 2025: Proven Strategies and Tips",
    subtitle: "Discover the best ways to make money online in 2024. From freelancing and blogging to affiliate marketing and e-commerce, explore actionable tips and strategies to build a sustainable income",
    id: "how-to-make-money-online-2025",
    image: require("../assets/images/post/post-6.webp"),
    category: "make-money",
    keywords: "make money online, online earning, freelancing, affiliate marketing, blogging, e-commerce, YouTube monetization, online courses, passive income, digital products",
  },
  {
    date: "09 Dec, 2024",
    title: "Proven Strategies to Get More Web Traffic in 2025",
    subtitle: "Discover actionable tips and proven strategies to increase web traffic to your blog or website. Learn the latest SEO techniques, content marketing strategies, and tools to grow your online presence in 2025",
    id: "proven-strategies-get-more-web-traffic-2025",
    image: require("../assets/images/post/post-7.webp"),
    category: "web-traffic",
    keywords: "get more web traffic, increase website traffic, SEO tips, content marketing, 2025 strategies",
  },
  {
    date: "09 Dec, 2024",
    title: "See How Much Traffic Your Competitors Are Getting: A Complete Guide",
    subtitle: "Learn how to track and analyze your competitors’ website traffic with the best tools and strategies. Gain insights into SEO, paid ads, backlinks, and more to stay ahead in the digital market",
    id: "see-how-much-traffic-your-competitors-are-getting",
    image: require("../assets/images/post/post-8.webp"),
    category: "entrepreneurship",
    keywords: "competitor traffic analysis, website traffic tools, competitor analysis SEO, track competitor traffic, organic search traffic, SEO strategies, online competitor research",
  },
  {
    date: "09 Dec, 2024",
    title: "How to Be Successful in Life: 33 Proven Strategies for Achieving Your Goals",
    subtitle: "Discover 33 practical strategies for success in life, from mindset shifts to actionable tips. These steps will help you achieve personal growth, build resilience, and reach your life goals",
    id: "how-to-be-successful-in-life-33-proven-strategies",
    image: require("../assets/images/post/post-4.webp"),
    category: "business-inspiration",
    keywords: "success strategies, achieve goals, personal growth, life success tips, motivational strategies",
  }
];
export default posts;
